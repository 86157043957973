import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
// import teamImg from "../../Assets/home-bg.jpg";
import visionImg from "../../Assets/vision.png";
import contactUsImg from "../../Assets/contact-us.png";
import teamImg from "../../Assets/team.png";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col md={8} className="about-img">
            <img src={visionImg} style={{borderRadius: "10px"}} alt="about" className="img-fluid" />
          </Col>
        </Row>
      <Row style={{ justifyContent: "center", padding: "10px 0" }}>
        <Col md={9}>
          <h1 style={{ fontSize: "2.1em" }}>
            <strong className="purple">愿景</strong>
          </h1>
          <p style={{ textAlign: "center", fontSize: "1rem" }}>
            <span className="purple">帮助用户在数字世界构建一个私人的安全屋，体验并创造美好的数字生活。</span>
          <br/>
            <span>我们的目标是帮助用户在数字世界中建设一个私人的安全屋，使他们能够完全控制自己的个人数据。 通过整合人工智能技术，用户能够无限扩展他们的能力，创造更美好的数字生活。</span>
          </p>
          </Col>
      </Row>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <Card className="quote-card-view">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <ul>
                    <li className="about-activity">
                        <h1 style={{ fontSize: "2rem", padding: "8px 0" }}>
                            团队介绍
                        </h1>
                    </li>
                    <li className="about-activity">
                      <p >我们是一支对科技和生活充满热情的团队。 我们的核心团队成员主要分布在深圳、香港和美国。 
                      我们的大多数成员都有超过十年的开发经验。 其中，有的在百度打造了过亿用户的互联网产品。 
                      还有人在美国安全公司McAfee工作了几年，在安全方面积累了深厚的知识，很早就接触了BTC。 
                      我们还有来自美国一家上市互联网金融公司创始团队的成员，拥有开发核心银行系统和应用程序的经验。 
                      现在，我们因为对web3的热情走到了一起，全身心投入到web3行业。 我们渴望为web3的发展贡献我们的力量。
                      </p>
                    </li>
                  </ul>
                  <footer className="blockquote-footer"></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col md={5} style={{ padding: "30px 0" }} className="about-img"
          >
            <img src={teamImg} className="img-fluid" />
          </Col>
        </Row>
        <Row style={{ justifyContent: "center", flexDirection: "row-reverse", padding: "10px 0" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <Card className="quote-card-view">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <ul>
                    <li className="about-activity">
                        <h1 style={{ fontSize: "2rem", padding: "8px 0" }}>
                            联系我们
                        </h1>
                    </li>
                    <li className="about-activity">
                      <p>如果您对我们的产品感兴趣或想加入我们，请随时直接给我们发电子邮件或通过我们的官方社交媒体渠道与我们联系。</p>

                      <p></p>
                      <p>Email:             support@tinyverse.space</p>
                      <p>Twitter:           https://twitter.com/tinyverse_space </p>
                      <p>Github:            https://github.com/tinyverse-web3 </p>
                      <p>Medium:            https://medium.com/@tinyverse_space </p>
                      <p></p>
                      <p>感谢您的关注。</p>
                    </li>
              
                  </ul>
                  <footer className="blockquote-footer"></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col
            md={5}
            style={{ padding: "30px 0" }}
            className="about-img"
          >
            <img src={contactUsImg} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default About;
