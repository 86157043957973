import React, { useState }  from "react";
import { Button, Carousel, Container, Row, Col, Modal } from "react-bootstrap";
import apkSvg from "../../Assets/apk-en.svg";
import appStoreSvg from "../../Assets/app-store.svg";
import googlePlaySvg from "../../Assets/google-play.svg";
import logo from "../../Assets/logo.png";

import spaceImg from "../../Assets/mtv-space.jpg";
import msgImg from "../../Assets/mtv-message.jpg";
import meImg from "../../Assets/mtv-me.jpg";
import createAccountImg from "../../Assets/mtv-create-account.jpg";

function Download() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <Container fluid className="download-section">
      <Container>
        <Row className="justify-content-md-center align-items-center">
          <Col md={6}>
            <Row className="justify-content-md-center">
                <h1 style={{ fontWeight: "bold", fontSize: "2.5rem", paddingBottom: "10px" }}>芥子空间</h1>
                <p style={{ fontSize: "1.5rem", paddingBottom: "10px" }}>为您在数字世界中建造一个私人安全屋。</p>
            </Row>
            <Row>
              <Col md={4}>
                <a onClick={handleShow}>
                  <img src={appStoreSvg} style={{ width: "200px", padding: "8px" }}/>
                </a>
              </Col>
              <Col md={4}>
                <a href="https://play.google.com/store/apps/details?id=com.tinyverse.tvs" target='_blank'>
                  <img src={googlePlaySvg} style={{ width: "200px", padding: "8px" }}/>
                </a>
              </Col>

              <Col md={4}>
                <a href='https://github.com/tinyverse-web3/mtv-app/releases/download/Release1.1.0/tinyverse-space-1.1.0-20230928175927.apk' target='_blank'>
                  <img src={apkSvg} style={{ width: "200px", padding: "8px" }}/>
                </a>
              </Col>
            </Row>
          </Col>
          <Col md={5}>
            <Carousel data-bs-theme="dark" style={{paddingTop: "100px"}}>
              <Carousel.Item>
                <img src={createAccountImg} style={{width: "50%", height: "50%", borderRadius: "0.5rem" }} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={spaceImg} style={{width: "50%", height: "50%", borderRadius: "0.5rem"}} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={msgImg} style={{width: "50%", height: "50%", borderRadius: "0.5rem"}} />
              </Carousel.Item>
              <Carousel.Item>
                <img src={meImg} style={{width: "50%", height: "50%", borderRadius: "0.5rem"}} />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tips</Modal.Title>
          </Modal.Header>
          <Modal.Body>The app is coming to the apple store.</Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} variant="primary">
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Container>
  );
}

export default Download;
