import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {
  AiFillMediumCircle,
  AiOutlineTwitter,
  AiFillGithub,
  AiOutlineMail,
} from 'react-icons/ai'

function Footer() {
  let date = new Date()
  let year = date.getFullYear()
  return (
    <Container fluid className='footer'>
      <Row>
        <Col md='2' className='footer-copywright'>
          <h3>TinyVerse</h3>
        </Col>
        <Col md='2' className='footer-copywright'>
          <h3>
            <a href='/privacy.html' target='_blank'>
              服务条款
            </a>
          </h3>
        </Col>
        <Col md='2' className='footer-copywright'>
          <h3>
            <a href='/service.html' target='_blank'>
              隐私政策
            </a>
          </h3>
        </Col>
        <Col md='2' className='footer-copywright'>
          <h3>Copyright © {year}</h3>
        </Col>
        <Col md='4' className='footer-body'>
          <ul className='footer-icons'>
            <li className='social-icons'>
              <a
                href='mailto:support@tinyverse.space'
                style={{ color: 'white' }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <AiOutlineMail />
                <span>support@tinyverse.space</span>
              </a>
            </li>
            <li className='social-icons'>
              <a
                href='https://medium.com/@tinyverse_space'
                style={{ color: 'white' }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <AiFillMediumCircle />
              </a>
            </li>
            <li className='social-icons'>
              <a
                href='https://twitter.com/tinyverse_space'
                style={{ color: 'white' }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <AiOutlineTwitter />
              </a>
            </li>
            <li className='social-icons'>
              <a
                href='https://github.com/tinyverse-web3'
                style={{ color: 'white' }}
                target='_blank'
                rel='noopener noreferrer'
              >
                <AiFillGithub />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  )
}

export default Footer
