import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Intro1 from "../Intro/Intro-1";
import Intro2 from "../Intro/Intro-2";
import Intro3 from "../Intro/Intro-3";
import Intro4 from "../Intro/Intro-4";
import Projects from "../Projects/Projects";
// import Partners from "./Partners";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">

              <h1 className="heading-name">
                <strong className="main-name">芥子空间</strong>
              </h1>

              <p className="heading">
                <br />数字世界中的个人安全屋
                <br />
                <br />存储和管理自己最重要的数据，探索数据资产化和资产增值的道路。
                <br />基于IPFS、开源、去中心化，您完全控制自己的账户和数据，任何第三方都无法访问。
                <br />
                <br />
                <br />
              </p>

              <p className="heading italic">
                    "风能进，雨能进，国王不能进"{" "}
              </p>

              <div style={{ padding: 50, textAlign: "left" }}>
                {/* <Type /> */}
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img src={homeLogo} className="img-fluid" style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Intro1 />
      <Intro2 />
      <Intro3 />
      <Intro4 />
      {/* <Projects /> */}
      {/* <Partners/> */}
    </section>
  );
}

export default Home;
