import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ImCheckmark } from "react-icons/im";
//import intro03Img from "../../Assets/intro03.png";
import intro03Img from "../../Assets/web30.png";

function Intro() {
  return (
    <Container fluid className="intro-section">
      <Container>
      <Row style={{ justifyContent: "center", padding: "10px 0" }}>
        <Col md={9}>
          <h1 style={{ fontSize: "2.1em" }}>
          存储和管理最重要的<strong className="purple">数据</strong>
          </h1>
          <p style={{ textAlign: "center", fontSize: "1rem" }}>
            <span className="purple">隐私数据（日记、账户密码、银行卡、通信录等）、积累的知识和经验、人生的经历、创作的成果、数字资产等等自己最重要的数据，需要有一个能保证隐私、数据安全、使用方便的地方永久存储。</span>
          <br/>
          </p>
          </Col>
      </Row>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <Card className="quote-card-view">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <ul>
                    <li className="about-activity">
                      <h3><ImCheckmark /> 透明的数据加解密</h3>
                      <p> 存储在用户数字空间的数据，自动由AES256算法加解密，除了用户自己，没有人能解密数据，保证数据的隐私。</p>
                    </li>
                    <li className="about-activity">
                      <h3><ImCheckmark /> 分散存储到IPFS网络</h3>
                      <p> 加密数据分散保存在IPFS网络，确保没有任何第三方可以访问和控制用户数据，保证数据的安全。</p>
                      <p> </p>
                    </li>
                    <li className="about-activity">
                      <h3><ImCheckmark /> 开源确保可信度</h3>
                      <p> app是开源的，确保一切操作如我们所言，任何人都可以检查代码以确保可靠性和安全性，发现和修复潜在的安全漏洞。
                      </p>
                    </li>

                  </ul>

                  <footer className="blockquote-footer"></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col md={5} style={{ padding: "50px 0" }} className="about-img">
            <img src={intro03Img} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
