import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ImCheckmark } from "react-icons/im";
//import intro01Img from "../../Assets/intro01.png";
import intro01Img from "../../Assets/explore.png";

function Intro() {
  return (
    <Container fluid className="intro-section">
     
      <Container>
      <Row style={{ justifyContent: "center", padding: "10px 0" }}>
        <Col md={9}>
          <h1 style={{ fontSize: "2.1em" }}>
              让钱生钱
          </h1>
          <p style={{ textAlign: "center", fontSize: "1rem" }}>
            <span className="purple">在安全的前提下，让自己的拥有的Token带来更多的Token是每个人都期望的事。（开发中）</span>
          <br/>
          <br/>
          </p>
          </Col>
      </Row>
        <Row style={{ justifyContent: "center", padding: "10px 0" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <Card className="quote-card-view">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <ul>
                    <li className="about-activity">
                      <h3><ImCheckmark /> 隐秘的量化交易策略</h3>
                      <p> 让自己的AI助手执行自己制定的交易策略，这是最安全的量化交易平台，因为这一切都运行在自己手机上，没有任何第三方了解自己的交易策略，从而制定针对自己的对手交易策略。</p>
                    </li>
                    <li className="about-activity">
                      <h3><ImCheckmark /> 去中心化的抵押</h3>
                      <p> 即使在家里放一个挂网的旧手机，都可能成为抵押节点的一部分，确保自己资金安全的同时，赚取无风险利息。
                      </p>
                    </li>
                    <li className="about-activity">
                      <h3><ImCheckmark /> 更多钱生钱的探索</h3>
                      <p> 我们持续探索安全的钱生钱的道路，因为也是我们自己的期望。
                      </p>
                    </li>
                  </ul>
                  <footer className="blockquote-footer"></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col md={5} style={{ padding: "50px 0" }} className="about-img">
            <img src={intro01Img} alt="about" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
