import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ImCheckmark } from "react-icons/im";
import laptopImg from "../../Assets/about.png";
//import intro04Img from "../../Assets/intro04.png";
import intro04Img from "../../Assets/ai01.png";

function Intro() {
  return (
    <Container fluid className="intro-section">
      <Container>
      <Row style={{ justifyContent: "center", flexDirection: "row-reverse",padding: "10px 0" }}>
        <Col md={9}>
          <h1 style={{ fontSize: "2.1em" }}>
              技术基础
          </h1>
          <p style={{ textAlign: "center", fontSize: "1rem" }}>
            <span className="purple">基于“自己掌控自己数据”的理念，我们为我们自己打造了一个安全的自我掌控的数字空间，保存自己最重要的数据，不用担心数据泄露，也不用担心数据遗失。这个空间最核心的技术包括：</span>
          <br/>
          </p>
          </Col>
      </Row>
        <Row style={{ justifyContent: "center", flexDirection: "row-reverse", padding: "10px 0" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <Card className="quote-card-view">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <ul>
                  <li className="about-activity">
                      <h3><ImCheckmark /> 基于非对称密钥的非托管账户</h3>
                      <p> 账户密钥由先进的Ed25519算法生成，用户数据由AES256算法加密，保障账户和数据的安全性。账户的私钥由用户自己管理，仅存在用户自己的手机内存中。</p>
                      <p> 用户面临的最大挑战就是如何安全并且方便的管理好自己的私钥，这是我们的核心工作。下面是我们的努力结果：</p>
                    </li>
                    <li className="about-activity">
                      <h3><ImCheckmark /> 多路径恢复账户</h3>
                      <p> 我们提供多种方式帮助用户备份和恢复账户，从此告别助记词丢失和被盗或者某个中心化服务关闭的<strong className="purple">单点故障</strong>风险。
                      <br/><strong className="purple">守护者恢复</strong>：通过验证守护者账号，取回密钥分片数据。
                      <br/><strong className="purple">智能隐私恢复</strong>：通过定制涉及个人隐私的问题和答案来生成私钥分片。
                      <br/><strong className="purple">加密助记词恢复</strong>：利用保险箱加密助记词，只有自己才能解开保险箱。
                      <br/><strong className="purple">智能继承恢复</strong>：利用智能合约，在账户太久没有动用时自动将控制权转给继承人。
                      </p>
                    </li>
                    <li className="about-activity">
                      <h3><ImCheckmark /> 多因素验证</h3>
                      <p> 用户需要分别验证保险箱和守护者账号才能得到并解密分片数据。这是两个独立的验证机制，充分利用了用户多个层面的隐私数据，不需要用户的额外记忆。
                      <br/><strong className="purple">保险箱</strong>：用户可以通过提供口令、隐私数据、指纹等作为模版数据来生成保险箱，保护用户的私钥分片数据。 
                      <br/><strong className="purple">守护者</strong>：用户依托自己的其他可以进行身份验证的账号，保存私钥分片数据到ipfs网络。</p>
                    </li>

                  </ul>


                  <footer className="blockquote-footer"></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col md={5} style={{ padding: "50px 0" }} className="about-img">
            <img src={intro04Img} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
