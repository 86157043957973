import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { ImCheckmark } from "react-icons/im";
//import intro02Img from "../../Assets/intro02.png";\
import intro02Img from "../../Assets/secure-storage.png";

function Intro() {
  return (
    <Container fluid className="intro-section">
      <Container>
      <Row style={{ justifyContent: "center", padding: "10px 0" }}>
        <Col md={9}>
          <h1 style={{ fontSize: "2.1em" }}>
          挖掘<strong className="purple">价值</strong>
          </h1>
          <p style={{ textAlign: "center", fontSize: "1rem" }}>
            <span className="purple">不同类型的数据有不同的价值，有些本身就等同于资产（比如钱包中的Token），有些的价值还有待挖掘。我们在这两个方面挖掘数据的价值：</span>
          <br/> 
          </p>
          </Col>
      </Row>
        <Row style={{ justifyContent: "center", flexDirection: "row-reverse", padding: "10px 0" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <Card className="quote-card-view">
              <Card.Body>
                <blockquote className="blockquote mb-0">
                  <ul>
                  <li className="about-activity">
                      <h3><ImCheckmark /> 创作者经济：作品资产化</h3>
                      <p>每个人都有创作天赋。个人自主发行作品成内容加密的NFT。</p>
                      <p>结合智能合约，不需要卖出NFT，而是通过付费查看合约赚取收入。还能通过转发分成合约激发作品的广泛传播，让个人作品的价值得到充分体现。</p>
                    </li>

                    <li className="about-activity">
                      <h3><ImCheckmark /> 个人智能助手</h3>
                      <p>通用的智能助手并不能带给个人独特的价值，只有在个人数据的基础上建设独特的个人智能助手，才能带给个人独特的价值，在竞争中保持优势。</p>
                      <p>个人的数据越多，AI越能发挥个人独特的优势和能力。个人隐私，积累的知识和经验，个人的作品，都可以用来训练个人智能助手。</p>
                    </li>
                    
                    <li className="about-activity">
                      <h3><ImCheckmark /> 探索更多的可能性</h3>
                      <p>这是我们持续努力的工作。如果您有什么好点子，请直接联系我们。</p>
                    </li>

                  </ul>


                  <footer className="blockquote-footer"></footer>
                </blockquote>
              </Card.Body>
            </Card>
          </Col>
          <Col md={5} style={{ padding: "50px 0" }} className="about-img">
            <img src={intro02Img} className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Intro;
